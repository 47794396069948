table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;
  }
  
  thead {
    background-color: #f2f2f2;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  