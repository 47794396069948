.loader-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    width: 100%;
    margin: 0;
    background-color: #e6e6e6cc;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4;
  }
  .loader {
    border: 10px solid lightgrey;
    border-top: 10px solid #1E3F5E;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }