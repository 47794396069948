
.small-section-wrapper{
    margin-top: 30px;
}
.slick-dots {
    justify-content: center;
}
.slick-slider{
    max-width: 2500px;
    margin: 0 auto;
}

.slick-dots {
    position: absolute;
    bottom: -35px;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Chrome and Safari specific prefixes */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .slick-dots li button:before {
        opacity: .25;
        color: black;
        -webkit-font-smoothing: antialiased;
    }
}



.title-wrapper{
    
        display: -webkit-box;
    
        display: -ms-flexbox;
    
        display: flex;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: end;
        height: 100%;
        padding-bottom: 20px;
        padding-left: 10px;
    
}


.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #0f273c;
    font-size: 30px;
}


.slick-prev{
    left: 10px;
    content: '<';
}

.slick-next{
right: 10px;

}

.slick-prev::before{
    /* position: absolute; */
    font-weight: 900;
    content: '\f053';
    top: 0;
    left: 10px;
    font-size: 27px;
    z-index: 1;
}
.slick-next::before{
    /* position: absolute; */
    font-weight: 900;
    content: '\f105';
    top: 0px;
    /* z-index: 1; */
    font-size: 27px;
    right: 10px;
}

.slick-arrow{
    font-family: 'Font Awesome\ 5 Free';
    /* border-radius: 5px; */
    position: absolute;
    outline: none;
    border: none;
    /* padding: 7px; */
    z-index: 1;
    bottom: 42%;
    color: #fff;
    font-size: 0;
    background-color: #0f273c;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding-left: 2px;
}

/* @media(max-width:768px){
    .slick-arrow{
        bottom: 150px;
    }
}
@media(max-width:430px){
    .slick-arrow{
        bottom: 90px;
    }
} */